import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Container } from '@mui/material';
import './HeroSection.css';

function HeroSection() {
  const heroRef = useRef(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    if (heroRef.current) {
      observer.observe(heroRef.current);
    }

    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      const { innerWidth, innerHeight } = window;
      
      setMousePosition({
        x: (clientX - innerWidth / 2) / innerWidth,
        y: (clientY - innerHeight / 2) / innerHeight
      });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      if (heroRef.current) {
        observer.unobserve(heroRef.current);
      }
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <Box 
      ref={heroRef}
      className="hero"
      style={{
        transform: `perspective(1000px) 
                   rotateX(${mousePosition.y * 2}deg) 
                   rotateY(${mousePosition.x * 2}deg)`
      }}
    >
      <div className="hero-background" />
      <Container maxWidth="lg" className="hero-content">
        <Typography 
          variant="h1" 
          className="hero-title"
        >
          <span className="gradient-text">Innovating</span> the
          <br />
          Future of <span className="gradient-text">SaaS</span>
        </Typography>
        
        <Typography 
          variant="h4" 
          className="hero-subtitle"
        >
          We buy, develop, and sell top-tier SaaS solutions.
        </Typography>

        <div className="hero-decorations">
          <div className="floating-orb orb-1" />
          <div className="floating-orb orb-2" />
          <div className="floating-orb orb-3" />
        </div>
      </Container>
    </Box>
  );
}

export default HeroSection; 