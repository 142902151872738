import React from 'react';
import { Box, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import NavBar from './components/NavBar';
import HeroSection from './components/HeroSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2D3436',
    },
    secondary: {
      main: '#00B894',
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ flexGrow: 1 }}>
        <NavBar />
        <HeroSection />
        <ContactSection />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
