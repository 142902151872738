import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import './NavBar.css';

function NavBar() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <AppBar position="fixed" className={`glass-nav ${scrolled ? 'scrolled' : ''}`}>
      <Toolbar>
        <Typography variant="h4" className="brand-text">
          <span className="text-gradient">NH</span>
          <span className="text-thin">Ventures</span>
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export default NavBar; 