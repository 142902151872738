import React, { useState } from 'react';
import { Box, Typography, IconButton, Snackbar } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmailIcon from '@mui/icons-material/Email';
import './ContactSection.css';

function ContactSection() {
  const [showCopied, setShowCopied] = useState(false);
  const email = 'contact@nhvc.io';

  const handleCopyEmail = () => {
    navigator.clipboard.writeText(email);
    setShowCopied(true);
  };

  return (
    <Box className="contact-section">
      <div className="contact-content">
        <Typography variant="h2" className="contact-title">
          Let's Connect
        </Typography>
        
        <div className="email-container">
          <EmailIcon className="email-icon" />
          <Typography variant="h4" className="email-text">
            {email}
          </Typography>
          <IconButton 
            onClick={handleCopyEmail}
            className="copy-button"
            aria-label="copy email"
          >
            <ContentCopyIcon />
          </IconButton>
        </div>

        <div className="decorative-line" />
      </div>

      <Snackbar
        open={showCopied}
        autoHideDuration={2000}
        onClose={() => setShowCopied(false)}
        message="Email copied to clipboard"
        className="copy-notification"
      />
    </Box>
  );
}

export default ContactSection; 