import React from 'react';
import { Box, Typography, Link } from '@mui/material';

function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: 'primary.main',
        color: '#fff',
        padding: '1rem 0',
        textAlign: 'center',
      }}
    >
      <Typography variant="body1">
        &copy; {new Date().getFullYear()} Your Name. All rights reserved.
      </Typography>
      <Typography variant="body2">
        <Link href="#" color="inherit" sx={{ margin: '0 0.5rem' }}>
          Privacy Policy
        </Link>
        |
        <Link href="#" color="inherit" sx={{ margin: '0 0.5rem' }}>
          Terms of Service
        </Link>
      </Typography>
    </Box>
  );
}

export default Footer; 